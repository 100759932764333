<template>
  <div class="home-block home-block__rows w-full">
    <div class="home-block__title">Инструкция</div>
    <div class="flex justify-content-center">
      <iframe
        src="https://www.youtube.com/embed/63fcC5Ensbs"
        title="Инструкция. Личный кабинет"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "video-instruction",
  setup() {},
};
</script>

<style scoped lang="scss">
iframe {
  width: 90%;
  max-width: 900px;
  height: 400px;

  @media (max-width: 768px) {
    height: 200px;
    margin-bottom: 20px;
  }
}
</style>