<template>
  <div class="home-block home-block__rows w-full">
    <div class="home-block__title">Мои группы</div>
    <div class="group-block table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th style="width: 420px">Название</th>
            <th>Выдано тестов</th>
            <th>Свободно тестов</th>
            <th>Выдано курсов</th>
            <th>Свободно курсов</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="group in groups" :key="group.id">
            <td>{{ group.name }}</td>
            <td>{{ group.test_count }}</td>
            <td>{{ group.free_test_count }}</td>
            <td>{{ group.course_count }}</td>
            <td>{{ group.free_course_count }}</td>
            <td>
              <router-link
                :to="`/group/${group.id}`"
                class="btn btn-accent sm-row__btn"
                >Перейти</router-link
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "groups",
  props: {
    groups: Array,
  },
};
</script>


<style lang="scss" scoped>
.group-block {
  padding: 0 10px;
}
.sm-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 25px;
  border-bottom: 1px solid var(--cream-color);

  &__title {
    font-size: 16px;
    max-width: 80%;
  }

  &__btn {
    min-width: 138px;
    margin-left: 15px;
  }
}
</style>