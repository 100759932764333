<template>
  <div class="home-block__big-row big-row">
    <i class="fal fa-file-chart-pie"></i>
    <div class="big-row__label">
      <div class="big-row__number">
        {{
          (tableData.finished.start ?? 0) +
          (tableData.finished.standard ?? 0) +
          (tableData.finished.full ?? 0)
        }}
      </div>
      <div class="big-row__text">Отчетов сформировано</div>
    </div>
  </div>
  <div class="home-block__big-row big-row">
    <i class="fal fa-user-tie"></i>
    <div class="big-row__label">
      <div class="big-row__number">
        {{ users ?? 0 }}
      </div>
      <div class="big-row__text">Численность работников</div>
    </div>
  </div>
  <div class="home-block__big-row big-row">
    <i class="fal fa-landmark"></i>
    <div class="big-row__label">
      <div class="big-row__number">
        {{ groups ?? 0 }}
      </div>
      <div class="big-row__text">Количество групп</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home-table2",
  props: {
    tableData: Object,
    users: Number,
    groups: Number,
  },
};
</script>

<style lang="scss" scoped>
.big-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 40px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--cream-color);
  }

  &__number {
    text-align: end;
    font-weight: 400;
    font-size: 20px;
    min-width: 60px;
  }

  &__text {
    font-size: 15px;
    padding-left: 10px;
    text-align: left;
    min-width: 176px;
  }

  &__btn {
    min-width: 138px;
  }

  &__label {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  i {
    color: var(--main-color);
    font-size: 40px;
    flex-basis: 50px;
    text-align: center;
  }

  @media (max-width: 1390px) {
    width: 100%;

    &__label {
      display: block;
    }

    &__text {
      text-align: right;
    }

    &__number {
      min-width: auto;
    }
  }
}
</style>